import { Controller } from "stimulus";
import anime from "animejs";
import { up, w, br_table } from "./../utils";
import Cookies from "js-cookie";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";

const easing = "spring(1, 80, 100, 5)";

export default class extends Controller {
    flyoutActive = false;
    mobileActive = false;
    currentMenuName = null;

    menus = {};

    menuAnimation;

    static targets = [
        "link",
        "bar",
        "flyout",
        "back",
        "burger",
        "bg",
        "mobileNav",
        "ribbon",
        "ribbonContent",
        "menuContent",
    ];

    connect() {
        // console.log('connect', this.linkTargets);
        this.linkTargets.forEach(this.initItem);

        const handleClose = (e) => {
            if (w.w > br_table) return;
            this.deactivateFlyout();
        };

        this.backTarget.addEventListener("click", handleClose);
        this.burgerTarget.addEventListener("click", this.toggleMobile);
        window.addEventListener("resize", this.onResize);
        window.addEventListener("load", this.onResize);

        this.onResize();
        this.showRibbon();
    }

    onResize = () => {
        const height = this.barTarget.offsetHeight;
        this.element.style.height = `${height}px`;
        this.mobileNavTarget.style.paddingTop = `${height}px`;
        this.flyoutTarget.style.top = `${height}px`;
        this.menuContentTargets.forEach(
            (t) => (t.style.paddingTop = `${height}px`)
        );

        if (w.w > br_table) {
            clearAllBodyScrollLocks();
        }
    };

    initItem = (item, index) => {
        const name = item.getAttribute("data-link-name");
        const link = item;
        const menu = this.element.querySelector(`[data-menu-name="${name}"]`);
        const wrap = menu.querySelector(`[data-menu-wrap]`);
        const content = menu.querySelector(`[data-menu-content]`);

        const handleOver = () => {
            if (w.w <= br_table) return;
            this.openMenu(name);
            this.activateFlyout();
        };

        const handleOut = (e) => {
            if (w.w <= br_table) return;

            // If mouse out went to menu // or another link
            // Don't close
            const to = e.relatedTarget;
            let target = up(to, function(parent) {
                return parent
                    ? parent.hasAttribute("data-menu-name") ||
                          parent.hasAttribute("data-link-name")
                    : false;
            });

            if (target === null) {
                this.closeMenu();
                this.deactivateFlyout();
            }
        };

        const handleClick = (e) => {
            if (this.currentMenuName === name){
                this.closeMenu();
                this.deactivateFlyout();
                return;
            }

            this.openMenu(name);
            this.activateFlyout();
        };

        const handleClickOut = (e) =>  {
            if(window.innerWidth >= 1100) {
                if (e.target.parentElement.classList.contains("track-menu")) {
                    return;
                }
                if (this.currentMenuName === null || this.currentMenuName === undefined) {
                    return
                } else {
                    if (e.target.classList.contains("nav__overlay-bg")) {
                        console.log(e.target);
                        this.closeMenu();
                        this.deactivateFlyout();
                    }
                }
            }
        }



        // link.addEventListener("mouseover", handleOver);
        // link.addEventListener("mouseout", handleOut);
        // menu.addEventListener("mouseover", handleOver);
        // menu.addEventListener("mouseout", handleOut);

        // link.addEventListener("click", handleOver);
        link.addEventListener("click", handleClick);
        menu.addEventListener("click", handleClick);

        window.addEventListener('click', handleClickOut);

        if (w.w > br_table) {
            anime.set(menu, { translateY: "-100%" });
            anime.set(this.bgTarget, { opacity: 0 });
        }

        this.menus[name] = { link, menu, index, wrap, content };
    };

    toggleMobile = () => {
        if (this.mobileActive) {
            this.mobileActive = false;
            this.element.classList.remove("is-mobile-menu-active");
            this.closeMenu();
            this.deactivateFlyout();

            // 4. Re-enable body scroll
            clearAllBodyScrollLocks();
            // console.log('enableBodyScroll', this.mobileNavTarget)
        } else {
            this.mobileActive = true;
            this.element.classList.add("is-mobile-menu-active");

            // 3. Disable body scroll
            disableBodyScroll(this.mobileNavTarget);
            // console.log('disableBodyScroll', this.mobileNavTarget)
        }
    };

    openMenu = (name) => {
        // console.log('openMenu', name, this.currentMenuName)
        if (this.currentMenuName === name) return;
        // Close current menu
        this.closeMenu();

        // Open new one
        const target = this.menus[name];
        target.menu.classList.add("is-menu-open");
        target.link.classList.add("is-active");

        this.currentMenuName = name;

        if (w.w > br_table) {
            anime.remove(target.menu);
            anime.remove(this.bgTarget);

            anime({
                targets: target.menu,
                translateY: "0%",
                translateZ: 0,
                easing,
            });

            anime({
                targets: this.bgTarget,
                opacity: 1,
                easing,
            });
        } else {
        }
    };

    closeMenu = () => {
        // console.log('closeMenu', this.currentMenuName)
        if (this.currentMenuName === null) return;

        const target = this.menus[this.currentMenuName];
        // Close menu
        target.menu.classList.remove("is-menu-open");
        target.link.classList.remove("is-active");
        this.currentMenuName = null;

        if (w.w > br_table) {
            anime.remove(target.menu);
            anime.remove(this.bgTarget);

            anime({
                targets: target.menu,
                translateY: "-100%",
                translateZ: 0,
                easing,
            });

            anime({
                targets: this.bgTarget,
                opacity: 0,
                easing,
            });
        } else {
        }
    };

    activateFlyout = () => {
        if (!this.flyoutActive) {
            // console.log('activateFlyout');
            this.flyoutActive = true;
            this.element.classList.add("is-flyout-active");

            if (w.w <= br_table) {
                const target = this.menus[this.currentMenuName];
                disableBodyScroll(target.content);
                // console.log('activateFlyout disableBodyScroll', target.content);
            }
        }
    };

    deactivateFlyout = () => {
        if (this.flyoutActive) {
            // console.log('deactivateFlyout');
            this.flyoutActive = false;
            this.element.classList.remove("is-flyout-active");

            if (w.w <= br_table) {
                // disableBodyScroll(this.mobileNavTarget);
                // console.log('deactivateFlyout disableBodyScroll');
            }
        }
    };

    showRibbon = () => {
        const cookieContent = Cookies.get("ribbon-banner");

        if (
            cookieContent &&
            cookieContent == this.ribbonContentTarget.textContent
        )
            return;

        this.element.classList.add("is-ribbon-active");

        // Make sure nav picks up new height without ribbon
        this.onResize();
    };

    hideRibbon = () => {
        Cookies.set("ribbon-banner", this.ribbonContentTarget.textContent);

        this.element.classList.remove("is-ribbon-active");

        // Make sure nav picks up new height without ribbon
        this.onResize();
        window.dispatchEvent(new Event("resize"));

        // Not sure how multiple ribbons work, let me know :)
        // Hide all Els
        // const banners = [].slice.call(document.querySelectorAll(`[data-controller='ribbon-banner']`))
        // banners.forEach(el => {
        //     el.classList.add(`ribbon-banner--hidden`)
        // })
    };
}
