import { Controller } from "stimulus";
import { favouritesService } from '~/js/_services';
import { windowLoad } from '../utils';

export default class extends Controller {

    connect() {
        windowLoad(this.init);
    };

    init = () => {
        this.text = this.element.textContent;
        
        this.subscription = favouritesService.current.subscribe(this.update);
    }
   
    update = (items) => {
        const { length: l } = items;
        this.element.innerHTML = `${this.text} (${l})`;
    };
}