import SwipeListener from 'swipe-listener';
import Overlay from "../Overlay";
import imagesloaded from 'imagesloaded';

const CLASS_ACTIVE = 'is-lightbox-active';
const CLASS_CURRENT = 'is-item-current';

export default class Lightbox {

    isActive = false;
    current = 0;

    items = [];
    
    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor() {
        this.overlay = Overlay.instance();
        this.overlay.addOnClick(() => { this.close() });

        this.element = document.getElementById('lightbox');


        this.closeEl = this.element.querySelector('[data-component="close"]');
        this.prevEl = this.element.querySelector('[data-component="prev"]');
        this.nextEl = this.element.querySelector('[data-component="next"]');
        this.listEl = this.element.querySelector('[data-component="list"]');
        this.overlayEl = this.element.querySelector('[data-component="overlay"]');
        
        this.closeEl.addEventListener('click', this.close);
        this.prevEl.addEventListener('click', this.goPrev);
        this.nextEl.addEventListener('click', this.goNext);
        this.nextEl.addEventListener('click', this.goNext);

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('load', this.handleResize);

        // Swipe left / right
        SwipeListener(this.overlayEl);
        this.overlayEl.addEventListener('swipe', this.handleSwipe);

        // Keys left / right
        window.addEventListener('keydown', this.handleKeydown);

        console.log('Lightbox, constructor', this);
    }

    handleKeydown = e => {
        // If slider not active -> dont trigger
        if (!this.isActive) return;
        if (e.keyCode === 37) this.goPrev();
        if (e.keyCode === 39) this.goNext();

        // On Esc press
        if (e.keyCode == 27) this.close();
    }

    handleSwipe = e => {
        const { directions } = e.detail;
        
        if (directions.left) this.goPrev();
        if (directions.right) this.goNext();
    };

    handleResize = () => {
        this.tick();
        this.items.forEach(this.update);
    };

    tick = () => {
        const rect = this.listEl.getBoundingClientRect();
        this.maxW = rect.width;
        this.maxH = rect.height;
    };

    update = (item, index) => {
        const { naturalWidth: w, naturalHeight: h } = item.img;

        const aspectImg = w/h;
        const aspectList = this.maxW/this.maxH;

        let newW = 'auto';
        let newH = 'auto';

        if (w > this.maxW || h > this.maxH) {
            if (aspectImg < aspectList) newH = `${this.maxH}px`;
            else newW = `${this.maxW}px`;
        };

        item.img.style.width = newW;
        item.img.style.height = newH;
    };

    create = (items) => {
        this.length = items.length;

        console.log('Lightbox, create', items);
        items.forEach(({url, caption}) => {
            const img = document.createElement('img');
            const itemEl = this.createEl('div', 'lightbox__item');
            const contentEl = this.createEl('div', 'lightbox__content');
            const imageEl = this.createEl('div', 'lightbox__image');
            const captionEl = this.createEl('div', 'lightbox__caption');

            img.setAttribute('data-src', url);
            img.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3C/svg%3E`
            captionEl.innerHTML = caption;

            imageEl.appendChild(img);
            contentEl.appendChild(imageEl);
            contentEl.appendChild(captionEl);
            itemEl.appendChild(contentEl);
            itemEl.classList.add('is-loading');
            itemEl.classList.add('should-lazy-load');

            this.items.push({ itemEl, img });
            this.listEl.appendChild(itemEl);

        });
        console.log('Lightbox, create finished', this.items);
        this.gotTo(0, false);
        this.handleResize();
    };

    createEl = (tag, className) => {
        const el = document.createElement(tag);
        el.classList.add(className);

        return el;
    }

    open = (index) => {
        console.log('Lightbox, open', index);
        this.current = index;
        this.gotTo(index);

        this.overlay.show();
        this.isActive = true;
        this.element.classList.add(CLASS_ACTIVE);
    };

    close = () => {
        this.isActive = false;
        this.overlay.hide();
        this.element.classList.remove(CLASS_ACTIVE);
    };

    gotTo = (index, loadImage = true) => {
        this.current = index;
        if(loadImage) {
            let item = this.items[index];
            let prev = this.items[index - 1 < 0 ? this.length - 1 : index - 1];
            let next = this.items[(this.current + 1) % this.length];
            let items = [item, next, prev];
            items.forEach(item => {
                if(item.itemEl.classList.contains('should-lazy-load')) {
                    item.img.src = item.img.getAttribute('data-src');
                    item.itemEl.classList.remove('should-lazy-load');
                    imagesloaded(item.img, (instance) => {
                        this.tick();
                        item.itemEl.classList.remove('is-loading');
                        item.itemEl.classList.add('is-finished-loading');
                        this.update(item);
                    })
                }
            })

        }
        this.items.forEach(({itemEl}) => itemEl.classList.remove(CLASS_CURRENT));
        this.items[index].itemEl.classList.add(CLASS_CURRENT);
    };

    goNext = () => {
        const index = (this.current + 1) % this.length;
        this.gotTo(index);
    }

    goPrev = () => {
        const index = this.current - 1;
        this.gotTo(index < 0 ? this.length - 1 : index);
    }
}

