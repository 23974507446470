import imagesloaded from 'imagesloaded';
const offset = 200;
import domLoaded from './dom-loaded';

domLoaded(() => {
    setupLazyLoad(document.querySelectorAll(".lazy"));
})



export function setupLazyLoad(lazyLoadImages) {

    let imageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let image = entry.target;
                const src = image.getAttribute('data-src');
                const srcSet = image.getAttribute('data-srcset');
                if(!src && !srcSet) {
                    imageObserver.unobserve(image);
                    return;
                }
                if(image.nodeName.toLowerCase() === 'img') {
                    if(src) {
                        image.src = src;
                    }
                    if(srcSet) {
                        image.srcSet = srcSet;
                    }
                } else {
                    if(src) {
                        image.style.backgroundImage = `url(${src})`
                    }
                }
                image.classList.remove("lazy");
                image.classList.add('img-loading');
                imageObserver.unobserve(image);
                imagesloaded(image, {background: true}, imageHasLoaded)
            }
        });
    }, {
        rootMargin: `0px 0px ${offset}px 0px`
    });

    lazyLoadImages.forEach(function(image) {
        imageObserver.observe(image);
    });
}

function imageHasLoaded(instance) {
    instance.images.forEach(image => {
        let element = image.element ? image.element : image.img;
        element.classList.remove('img-loading');
        element.classList.add('img-loaded');

    })
}
