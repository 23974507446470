import { Controller } from "stimulus";
import "url-search-params-polyfill";

export default class extends Controller {
    initialize() {}

    connect() {
        const dealer = window.current_dealer;
        const vehicle = window.current_vehicle;
        const price = window.current_total_price;

        let id = dealer.data["dealership-details"].heartland_id
        if (!id){
             id = 1;
        }
        this.element.setAttribute(
            "href",
            `https://digital.heartland.co.nz/#/apply-for-armstrongs-finance/armstrongs-car-loan-calculator?pc=${id}&price=${price}&year=${vehicle['@LISTING_YEAR']}&make=${vehicle['@LISTING_MAKE']}&model=${vehicle['@LISTING_MODEL']}`
        );
    }
}
