import { Controller } from "stimulus";
import Lightbox from "../components/lightbox";
import fbTracking from "../fbTracking";

export default class extends Controller {
    data = [];

    static targets = ["item"];

    initialize() {
        this.lightbox = Lightbox.instance();
    }

    connect() {
        this.data = window.images_data ?? null;

        if (this.data) {
            this.init();
        }
    }

    init = () => {
        const items = this.data.map((item) => {
            return {
                url: item["@url"].includes("armstrongs-autoimages") ? item["@url"] : `${item["@url"]}&w=1200&q=80&compress=true`,
                caption: "",
            };
        });

        console.log(
            "lightbox",
            this.data,
            items,
            this.lightbox,
            this.itemTargets
        );
        this.lightbox.create(items);

        this.itemTargets.forEach((item, index) => {
            item.addEventListener("click", () => {
                this.lightbox.open(index);
            });
        });
    };

    open = () => {
        this.lightbox.open(0);
    };
}
