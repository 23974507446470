import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "item",
    ];

    connect() {
        this.run();
    }

    run() {
        console.log(this.itemTargets)
    }
}