import { Controller } from "stimulus";
import Confirmation from "../components/Confirmation";

const confirmation = Confirmation.instance();

export default class extends Controller {

    static targets = [
        "url",
        "twitter",
        "facebook"
    ]

    copy() {
        navigator.clipboard.writeText(window.location);
        confirmation.open({
            msg: 'Copied link to clipboard!'
        });
    }

    send(event) {
        const url = event.target.dataset.shareUrl + window.location
        window.open(url)
    }
}
