import { Controller } from "stimulus";

export default class extends Controller {
    showSubmit = (e) => {
        let input = document.querySelector('.newsletter-input');
        if (input.checkValidity()) {
            let form = document.querySelector('#mc-embedded-subscribe-form')
            let success = document.querySelector('.newsletter-success')
            form.style.display = "none";
            success.style.display = "block";
        }
    };
}
