import { Controller } from "stimulus";
import "url-search-params-polyfill";
import PopupForm from "../components/popup-form";
import fbTracking from "../fbTracking";

let models = [];
let regions = [];
const jsonUrl = "/content/json/";

export default class extends Controller {
    initialize() {
        this.popupForm = PopupForm.instance();
    }

    async connect() {
        this.formType = this.element.getAttribute("data-form");



        this.inlineForm = this.element.getAttribute("inline-form");

        this.passedEmail = this.element.getAttribute("data-form-email");
        this.models = models;

        this.formId = this.element.getAttribute("data-form-id");
        this.shareTitle = this.element.getAttribute("data-share-title");
        this.fbTrackingEvent = this.element.getAttribute("data-fb-tracking");

        this.depositFormContent = {
            intro: this.element.getAttribute("data-deposit-form-intro"),
            terms: this.element.getAttribute("data-deposit-form-terms"),
            title: this.element.getAttribute("data-deposit-form-title"),
        };
        this.element.addEventListener("click", this.handleClick);

        this.vehicle = window.current_vehicle;
        this.dealer = window.current_dealer;

        if(this.inlineForm){
            this.initForm();
        }
    }

    initForm = async () => {
        this.regions = await this.loadDataRegions();
        regions = this.regions;
        if(this.formType === "make-trade-enquiry"){
            try {
                models = await this.loadDataModels();
                this.models = models;
            } catch (error) {
                console.error('Error loading data:', error);
            }
        }
        if (this.fbTrackingEvent) {
            window.fbq("track", this.fbTrackingEvent, fbTracking(this.vehicle));
        }

        if (
            this.formType === "dynamic-form" ||
            this.formType === "place-a-deposit"
        ) {
            const $ = jQuery; // oh god!
            const $popupForm = $(this.popupForm.element);

            const popupContentReplacedClass =
                this.formType === "dynamic-form"
                    ? ".popup-form__body"
                    : ".popup-form__body .gravity-form-content";
            const showTitle = this.formType === "dynamic-form" ? true : false;
            const showDescription =
                this.formType === "dynamic-form" ? true : false;

            const setBusy = (busy) => {
                this.element.classList[busy ? "add" : "remove"](
                    "button--loading"
                );
                this.element.disabled = busy;
            };

            setBusy(true);
            const vehicle_id = this.vehicle
                ? this.vehicle["@LISTING_SEQ"]
                : null;
            const make = this.vehicle ? this.vehicle["@LISTING_MAKE"] : null;
            const model = this.vehicle ? this.vehicle["@LISTING_MODEL"] : null;
            const year = this.vehicle ? this.vehicle["@LISTING_YEAR"] : null;
            const price = this.vehicle
                ? this.vehicle["@LISTING_PRICE_DISPLAY"]
                : null;
            const plate = this.vehicle ? this.vehicle["@LISTING_PLATE"] : null;
            const dealership = this.dealer ? this.dealer["post_title"] : null;
            const dealershipid = this.dealer ? this.dealer['data']['autoplay']['dealer'] : null;
            const otherData = {
                "show-title": showTitle,
                "show-description": showDescription,
            };

            const carDetails = Object.assign(
                {},
                vehicle_id === null ? null : {vehicle_id},
                make === null ? null : {make},
                model === null ? null : {model},
                year === null ? null : {year},
                price === null ? null : {price},
                plate === null ? null : {plate},
                dealership === null ? null : {dealership},
                dealershipid === null ? null : {dealershipid},

                otherData
            );

            const queryParams = new URLSearchParams(carDetails);

            fetch(`/form/${this.formId}?${queryParams.toString()}`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded", // needed for using URLSearchParams polyfill
                },
            })
                .then((response) => {
                    return response.text();
                })
                .then((html) => {
                    this.popupForm.open({
                        type: this.formType,
                        formId: this.formId,
                        additionalData: this.depositFormContent,
                        models: this.models,
                        regions: this.regions,
                    });

                    $popupForm
                        .find(popupContentReplacedClass)
                        .first()
                        .html(html);

                    if (window["gformInitDatepicker"]) {
                        gformInitDatepicker();
                    }
                    if ($('.cf-turnstile').length >= 1) {
                        turnstile.render(".cf-turnstile");
                    }

                    setBusy(false);
                })
                .catch((e) => {
                    console.log(e);
                })
                .then((response) => {
                    return response.text();
                })
                .then((html) => {
                    this.popupForm.open({
                        type: this.formType,
                        formId: this.formId,
                        additionalData: this.depositFormContent,
                        models: this.models,
                        regions: this.regions,
                    });
                    $popupForm
                        .find(popupContentReplacedClass)
                        .first()
                        .html(html);
                    if (window["gformInitDatepicker"]) {
                        gformInitDatepicker();
                    }
                    setBusy(false);
                })
                .catch((e) => {
                    console.log(e);
                })
                .then((response) => {
                    return response.text();
                })
                .then((html) => {
                    this.popupForm.open({
                        type: this.formType,
                        formId: this.formId,
                        models: this.models,
                        regions: this.regions,

                    });
                    $popupForm
                        .find(popupContentReplacedClass)
                        .first()
                        .html(html);
                    if (window["gformInitDatepicker"]) {
                        gformInitDatepicker();
                    }
                    setBusy(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            if (
                this.formType === "test-drive-dealer" ||
                this.formType === "test-drive-branded" ||
                this.formType === "test-drive-offer"
            ) {
                const modelPrefill = this.element.getAttribute(
                    "data-model-prefill"
                );
                this.popupForm.open({
                    type: this.formType,
                    modelPrefill: modelPrefill,
                    models: this.models,
                    regions: this.regions,

                });
            } else if (this.formType === "share-url") {
                this.popupForm.open({
                    type: this.formType,
                    shareTitle: this.shareTitle,
                    models: this.models,
                    regions: this.regions,
                });
            } else {
                if (this.inlineForm) {
                    import(/* webpackChunkName: "inline-form" */ '../components/popup-form/index-inline').then(module => {
                        // console.log(this.element, module);
                        module.default(this.element, {
                            type: this.formType,
                            inline: this.inlineForm,
                            email: this.passedEmail,
                            models: this.models,
                            regions: this.regions,
                        });
                    })
                } else {
                    this.popupForm.open({
                        type: this.formType,
                        email: this.passedEmail,
                        models: this.models,
                        regions: this.regions,
                    });
                }
            }
        }
    }
    handleClick = (e) => {
        if(!this.inlineForm) {
            e.preventDefault();
        }

        this.initForm();

    };
    async loadDataVehicles() {
        try {
            const response = await fetch(jsonUrl + "vehicles.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }
    }
    async loadDataModels() {
        try {
            const response = await fetch(jsonUrl + "models.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }

    }
    async loadDataRegions() {
        try {
            const response = await fetch(jsonUrl + "regions.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }

    }
}
