import { Controller } from "stimulus";
import OpSlider from "./../components/op-slider";
import { windowLoad } from '../utils';

export default class extends Controller {
    scale = 1.1;

    static targets = [
        "frame",
        "list",
        "item",
        "bg",
        "dots",
        "progress"
    ];

    connect() {
        if(!this.hasItemTarget) return;
        if (this.itemTargets.length < 2) return;
        windowLoad(this.init);
    }

    init = () => {
        this.length = this.itemTargets.length;

        const dots = this.itemTargets.map((item, index) => {
            const li = document.createElement('li');
            this.dotsTarget.appendChild(li);

            li.addEventListener('click', () => this.slider.select(index));
            return li;
        });

        // const p = this.progressTarget;
        
        // const percent = 100/this.length;
        // p.style.width = `${percent}%`;

        const onSelect = ({newIndex, prevIndex}) => {
            // p.style.transform = `translate3d(${100 * newIndex}%, 0, 0)`;
            dots[prevIndex].classList.remove('is-selected');
            dots[newIndex].classList.add('is-selected');
            
            this.itemTargets[prevIndex].classList.remove('is-selected');
            this.itemTargets[newIndex].classList.add('is-selected');
        };

        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            onSelect,
            stopMove: true,
            autoplay: {
                delay: 10000
            }
        };

        this.slider = new OpSlider(config);
    };

}
