import { Controller } from "stimulus";
import OpSlider from "./../components/op-slider";
import anime from "animejs";
import roundTo from 'round-to';
import { windowLoad } from '../utils';

export default class extends Controller {

    scale = 0.8;

    static targets = [
        "frame",
        "list",
        "item",
        "content",
        "image",
        "left",
        "right",
        "progress"
    ];

    connect() {
        if(!this.hasItemTarget) return;
        this.length = this.itemTargets.length;

        windowLoad(this.init);
    }

    init = () => {

        const timelines = this.contentTargets.map((item, index) => {
            return anime.timeline({
                autoplay: false,
                duration: 1000,
                easing: 'easeOutSine',
            }).add({
                targets: item,
                scaleX: [1, this.scale],
                scaleY: [1, this.scale],
                translateZ: 0,
            })
        });

        const slideAnimation = ({ index, progress }) => {
            // if (Math.abs(progress) < 1) {
                const pr = roundTo(Math.abs(progress), 6);
                timelines[index].seek(timelines[index].duration * pr);
            // }
        };

        const p = this.progressTarget;

        const percent = 100/this.length;
        p.style.width = `${percent}%`;

        const onSelect = ({newIndex, prevIndex}) => {
            p.style.transform = `translate3d(${100 * newIndex}%, 0, 0)`;
        };

        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            prev: { targets: [this.leftTarget] },
            next: { targets: [this.rightTarget] },
            preSelect: 0,
            onSelect,
            slideAnimation
        };

        this.slider = new OpSlider(config);
    }

}
