export default class Tabs {
    selected = null;

    constructor({ tabs, contents, selected, tabsContainer }) {
        this.contents = contents;
        this.items = tabs.map(this.initItem);

        if (this.items.length < 2) {
            if (tabsContainer) tabsContainer.style.display = "none";
        }

        if (selected !== null) {
            this.open(parseInt(selected));
        } else {
            this.open(0);
        }
    }

    initItem = (tab, index) => {
        const content = this.contents[index];

        const handleClcik = () => {
            this.close();
            this.open(index);
        };

        tab.addEventListener("click", handleClcik);

        return { tab, content };
    };

    open = (index) => {
        if (this.selected === index) return;
        this.selected = index;
        const { tab, content } = this.items[index];
        tab.classList.add("is-selected");
        content.classList.add("is-selected");
    };

    close = () => {
        const { tab, content } = this.items[this.selected];
        tab.classList.remove("is-selected");
        content.classList.remove("is-selected");
        this.selected = null;
    };
}
