import { Controller } from "stimulus";
import { windowLoad } from '../utils';

export default class extends Controller {

    static targets = [
        "bg",
        "logos",
    ];

    connect() {
        windowLoad(this.tick);
        window.addEventListener('resize', this.tick);
    }

    tick = () => {
        const rect = this.logosTarget.getBoundingClientRect();
        this.bgTarget.style.bottom = `${rect.height/2}px`;
    }
}