// import { Controller } from "stimulus";
// import "url-search-params-polyfill";

// export default class extends Controller {
//     initialize() {}

//     connect() {
//         window.heartland_id = this.element.value;

//         this.element.addEventListener("change", () => {
//             window.heartland_id = this.element.value;
//         });
//     }
// }

import { Controller } from "stimulus";

export default class extends Controller {
    async initialize() {
        let regions = await this.loadDataRegions();
        import(
            /* webpackChunkName: "offer-card-all" */ "../components/FinanceSelect"
            ).then((module) => {
            // console.log(this.element, module);
            module.default(this.element, {regions: regions});
        });
    }
    async loadDataRegions() {
        try {
            const response = await fetch( "/content/json/regions.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }

    }
}
