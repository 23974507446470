export default class Accordion {
    constructor({accordion}) {
        this.initItem(accordion)
    }

    initItem = (accordion) => {

        let triggers = accordion.querySelectorAll(".accordion-button");
        for (let i = 0; i < triggers.length; i++) {
            const handleClick = () => {
                this.toggle(accordion, triggers[i]);
            };
            triggers[i].addEventListener('click', handleClick);
        }
    };

    toggle = (accordion, trigger) => {
        let target = accordion.querySelector(`[id=${trigger.getAttribute('aria-controls')}]`);

        if (target.classList.contains('show')) {
            target.classList.remove('show');
            trigger.setAttribute('aria-expanded', 'false');
        } else {
            target.classList.add('show');
            trigger.setAttribute('aria-expanded', 'true');
        }
    };

}
