import { Controller } from "stimulus";
import fbTracking from "../fbTracking";

export default class extends Controller {
    initialize() {}

    connect() {
        const vehicle = window.current_vehicle;
        window.fbq("track", "ViewContent", fbTracking(vehicle));
    }
}
