import { Controller } from "stimulus";
const jsonUrl = "/content/json/";

export default class extends Controller {
    async initialize() {

        import(/* webpackChunkName: "vehicles-filters" */ '../components/VehiclesFilters').then(async module => {
            // console.log(this.element, module);
            try {
                const vehicleResponse = await this.loadDataVehicles();
                const modelResponse = await this.loadDataModels();
                const regionResponse = await this.loadRegionData()
                module.default(this.element, {vehicles: vehicleResponse, models: modelResponse, regions: regionResponse});
            } catch (error) {
                console.error('Error loading data:', error);
            }
        })
    }

    async loadDataVehicles() {
        try {
            const response = await fetch(jsonUrl + "vehicles.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }
    }
    async loadRegionData() {
        try {
            const response = await fetch(jsonUrl + "regions.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }
    }
    async loadDataModels() {
        try {
            const response = await fetch(jsonUrl + "models.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }

    }
}