import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "frame",
        "list",
        "left",
        "right"
    ];

    async initialize() {
        try {
            const regionResponse = await this.loadRegionData()
            import(/* webpackChunkName: "vehicle-tiles" */ '../components/VehicleTiles').then(module => {
                module.default(this.listTarget, {
                    slider: this.element,
                    frame: this.frameTarget,
                    list: this.listTarget,
                    left: this.leftTarget,
                    right: this.rightTarget,
                    regions: regionResponse,
                });
            })
        } catch (e) {

        }
    }
    async loadRegionData() {
        try {
            const response = await fetch("/content/json/regions.json");
            return await response.json();
        } catch (error) {
            console.error('Error fetching JSON:', error);
        }
    }
}