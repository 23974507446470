import { Controller } from "stimulus";
import Accordion from "../components/accordion";

export default class extends Controller {
    connect() {
        this.accordion = this.element;
        if (!this.accordion) return;

        const tabs = new Accordion({
            accordion: this.accordion
        })
    }
}
