import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import ReactSlickSlider from "../components/react-slider/ReactSlickSlider";
import Overlay from "../components/Overlay";

export default class extends Controller {
    static targets = ["overlay"];

    connect() {
        this.currentImages = window.images_data.map(image => (image["@url"] !== undefined ? image["@url"]+"?id=81040321&w=768&q=80&compress=true" : undefined)).filter(url => url !== undefined);
        this.slickSlider = ReactSlickSlider.instance();
    }

    showSlider() {
        const slider = document.getElementById("react-slick-slider");
        slider.classList.remove("hidden");

        const clickedElement = event.target.closest("[data-index]");
        const index = clickedElement.dataset.index;

        this.sliderButtonHome = document.getElementById('slider-arrows-group');
        this.overlay = Overlay.instance();

        this.overlay.show();
        this.sliderButtonHome.classList.remove("hidden");

        ReactDOM.render(
            <ReactSlickSlider
                images={this.currentImages}
                initialIndex = {index}
            />,
            document.getElementById("react-slick-slider")
        );
    }

    hideSlider() {
        const overlay = document.getElementById("slider-overlay");
        overlay.classList.add("hidden");
        ReactDOM.unmountComponentAtNode(document.getElementById("react-slick-slider"));
    }
}
