import React from "react";
import Slider from "react-slick";
import Overlay from "../Overlay";

class ReactSlickSlider extends React.Component {
    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }
    getMeta(url, callback) {
        const img = new Image();
        img.src = url;
        img.onload = function() { callback(this.width, this.height); }
    }
    constructor(props) {
        super(props);  // Calls the constructor of the parent class (React.Component)
        this.state = {
            size: 0,
            leftOfset: 0,
            topOffset:0,
            nav1: null,
            nav2: null,
            currentSlideIndex: props.initialIndex || 0,
            aspectRatio : 0.66
        };
        // Create refs
        this.parentRef = React.createRef();
        this.sliderRef1 = React.createRef();
        this.sliderRef2 = React.createRef();

        this.overlay = Overlay.instance();
        this.overlay.addOnClick(() => { this.close() });

        this.sliderPrev = document.getElementById('slider-arrow-prev');
        this.sliderNext = document.getElementById('slider-arrow-next');
        this.sliderButtonHome = document.getElementById('slider-arrows-group');
        this.closeElement = document.getElementById('slider-arrow-close');

        window.addEventListener('keydown', this.handleKeydown);
        this.closeElement.addEventListener('click', this.close);
        this.sliderPrev.addEventListener('click', this.goPrev);
        this.sliderNext.addEventListener('click', this.goNext);

        this.current_vehicle_title = window.current_vehicle['@LISTING_TITLE'] ?? '';
        this.getMeta(window.images_data[0]['@url'])
        if(window.images_data[0]) {
            this.getMeta(
                window.images_data[0]['@url'] + "?id=81040321&w=768&q=80&compress=true",
                (width, height) => {
                    this.state = {
                        ...this.state,
                        aspectRatio : height / width
                    }
                }
            );
        }
    }
    handleKeydown = e => {
        // If slider not active -> dont trigger
        if (e.keyCode === 37) this.goPrev();
        if (e.keyCode === 39) this.goNext();

        // On Esc press
        if (e.keyCode == 27) this.close();
    }
    goNext = () => {
        this.sliderRef1.current.slickNext(this.state.currentSlideIndex);
    }

    goPrev = () => {
        this.sliderRef1.current.slickPrev()
    }

    close = () => {
        this.overlay.hide();

        const slider = document.getElementById("react-slick-slider");
        slider.classList.add("hidden");

        this.sliderButtonHome.classList.add("hidden");


    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.initialIndex !== this.props.initialIndex) {
            this.changeBothSliders( this.props.initialIndex);
        }
    }

    componentDidMount() {
        // Set the refs after the component has mounted
        this.setState({
            nav1: this.sliderRef1.current,
            nav2: this.sliderRef2.current,
        });

        // Update size on initial mount
        this.updateSize();

        window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount() {
        // Clean up the resize event listener on component unmount
        window.removeEventListener("resize", this.updateSize);
    }

    onClickMenuSlider(index){
        this.changeBothSliders(index);
    }

    changeBothSliders(index){
        // this.sliderRef2.current.slickGoTo(index);
        this.sliderRef1.current.slickGoTo(index);
        this.setState({currentSlideIndex:index});
    }
    updateSize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        let newSize;

        if (width < 600) {
            // Mobile: Static size of 500px
            newSize = 384
        } else if (width >= 600 && width < 1024) {
            // Tablet: Static size of 600px
            newSize = 438

        } else if (width >= 1024 && width < 1440) {
            // Small desktop: Static size of 700px
            newSize = 614;
        } else {
            // Large desktop: Static size of 800px
            newSize = 768;
        }


        this.setState({ size: newSize }, () =>{
            let offSet = ((width - this.parentRef.current.offsetWidth) / 2);
            if(offSet < 0){
                offSet = 20
            }
            this.setState({
                    leftOffset: offSet,
                    topOffset: 0
                }
            );
        });
    };
    handleAfterChange = (currentIndex) => {
        this.setState({currentSlideIndex:currentIndex});
    };

    render() {
        const { images } = this.props;
        const { size, nav1, nav2, leftOffset, topOffset, aspectRatio } = this.state;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: this.handleAfterChange,
            initialSlide: this.state.currentSlideIndex,
    };

        return (
            <div className={`react-slick-slider`}

                 style={{
                     left: `${leftOffset}px`,
                     top: `6%`,
                 }}
            >
                <style>
                    {
                        `.slick-slider-one{
                        .slick-list{
                            height: ${(size * aspectRatio) + 35}px;
                        }
                        img{
                             height: ${(size * aspectRatio)}px;
                            }
                        }
                         .slick-slider-two{
                           height: ${(size /10)}px;
                         }
                         `

                    }
                </style>


                <div
                    style={{
                        width: `${size}px`,
                        height: `${(size * 3) / 4}px`,
                        left: `${leftOffset}px`
                    }}
                    ref={this.parentRef}

                >
                    <div className="col-12">
                        <div className="slider-container">
                            <Slider
                                className="slick-slider-one"
                                asNavFor={nav2}
                                ref={this.sliderRef1}
                                {...settings}
                            >
                                {images.map((image, index) => (
                                    <div key={index} className={`slider-container`}>
                                        <div className={`row`}>
                                            <div className={`col-9`}>
                                                <b>{this.current_vehicle_title}</b>
                                            </div>
                                            <div className={`col-3 text-align-right`}>
                                                <b >{index + 1} / {images.length}</b>
                                            </div>
                                        </div>
                                        <img src={image} alt={`Slide ${index + 1}`}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <Slider
                        className="slick-slider-two"
                        ref={this.sliderRef2}
                        slidesToShow={5}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        centerMode={true}
                    >
                        {images.map((image, index) => (
                            <div key={index}>
                                <div className={`imgMargin`}>
                                    <img
                                        className={`${this.state.currentSlideIndex == index ? `slick-current-parent` : ``}`}
                                        onClick={() => {
                                            this.onClickMenuSlider(index)
                                        }}
                                        src={image} alt={`Slide ${index + 1}`}/>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default ReactSlickSlider;
