import { BehaviorSubject } from 'rxjs';
import Confirmation from "../components/Confirmation";
import { axiosInstance } from './config';

const subject = new BehaviorSubject(JSON.parse(localStorage.getItem('favourite_vehicles')) || []);
const confirmation = Confirmation.instance();

export const favouritesService = {
    addFavourite,
    removeFavourite,
    isFavourite,
    clearFavourites,
    current: subject.asObservable(),
    get copy () { return JSON.parse(JSON.stringify(subject.value))},
    get value () { return subject.value }
};

function addFavourite(id) {
    if (isFavourite(id)) {
        // console.log('favouritesService - already favourite');
        return;
    }
    const vehicles = favouritesService.copy;

    vehicles.push({ id });
    localStorage.setItem('favourite_vehicles', JSON.stringify(vehicles));
    subject.next(vehicles);
    confirmation.open({
        msg: 'Added to favourites!'
    });
}

function removeFavourite(id) {
    const vehicles = favouritesService.copy;
    const index = vehicles.findIndex(v => v.id === id);

    // console.log(`Service removeFavourite copy`, vehicles);
    // console.log(`Service removeFavourite id: ${id}, found: ${index}`);

    if (index !== -1) {
        vehicles.splice(index, 1);
        // console.log(`Service removeFavourite after removing`, vehicles);
        localStorage.setItem('favourite_vehicles', JSON.stringify(vehicles));
        subject.next(vehicles);

        confirmation.open({
            msg: 'Removed from favourites!'
        });
    }
}

function isFavourite(id) {
    const vehicles = favouritesService.value;

    // console.log('Service isFavourite - value:', vehicles);
    // console.log('Service isFavourite - check:', id, vehicles.findIndex(v => v.id === id) !== -1);
    return vehicles.findIndex(v => v.id === id) !== -1;
}

function clearFavourites() {
    localStorage.setItem('favourite_vehicles', JSON.stringify(''));
}

function apiCheckFavourites(id) {
    const vehicles = favouritesService.value;

    let requests = vehicles.map(vehicle => {
        return axiosInstance.get(`/api/v1/vehicle/${vehicle.id}`).then((response) => {
            
            console.log('apiCheckFavourites', vehicle.id, response)

            if (!response.data) {
                // If not favourite anymore, remove it
                removeFavourite(vehicle.id);
            }
            
        }, () => {
        });
    });

    Promise.all(requests).then(result => {
        // console.log('finished apiCheckFavourites', result);
    });
}

// Check on page load once
apiCheckFavourites();