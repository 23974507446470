
const w = {
    w: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    h: Math.max( document.documentElement.clientHeight, window.innerHeight || 0)
};

const handleResize = () => {
    w.w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    w.h = Math.max( document.documentElement.clientHeight, window.innerHeight || 0);
};

window.addEventListener('resize', handleResize);
window.addEventListener('load', handleResize);

export { w };