export default (data) => {
    const id = data["@LISTING_VIN"];
    const make = data["@LISTING_MAKE"];
    const model = data["@LISTING_MODEL"];
    const state_of_vehicle = data["@LISTING_NEW"] ? "new" : "used";
    const year = data["@LISTING_YEAR"];
    const exterior_color = data["@LISTING_COLOUR"];
    const transmission = data["@LISTING_TRANSMISSION"];
    const body_style = data["@LISTING_BODY_TYPE"];
    const fuel_type = data["@LISTING_FUEL_TYPE"];
    const drivetrain = data["@LISTING_DRIVE_TRAIN"];
    const price = parseFloat(data["@LISTING_PRICE_INCL"]);

    return {
        content_type: "vehicle", // REQUIRED: DO NOT change this, must be set to  'vehicle'
        content_ids: [id], // REQUIRED: array of vehicle IDs
        country: "New Zealand", // RECOMMENDED  don't use abbreviations
        make, // RECOMMENDED
        model, // RECOMMENDED
        year, // RECOMMENDED
        state_of_vehicle, // RECOMMENDED
        exterior_color, // RECOMMENDED
        transmission, // RECOMMENDED
        body_style, // RECOMMENDED
        fuel_type, // RECOMMENDED
        drivetrain, // RECOMMDENDED
        price, // RECOMMENDED, up to 2 decimals optional
        currency: "NZD", // REQUIRED if price and preferred_price_range is used, currency has to be the same for both price and preferred_price_range
    };
};
