const CLASS_ACTIVE = "is-confirmation-active";

export default class Confirmation {
    isActive = false;

    static instance() {
        if (this._instance) return this._instance;
        return (this._instance = new this());
    }

    constructor() {
        this.element = document.createElement("div");
        this.text = document.createElement("div");
        this.element.classList.add("confirmation");
        this.text.classList.add("confirmation__text");

        this.element.appendChild(this.text);
        if (document.body) document.body.appendChild(this.element);
    }

    open = ({ msg }) => {
        this.isActive = true;
        this.text.innerHTML = msg;
        this.element.classList.add(CLASS_ACTIVE);

        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(this.close, 3000);
    };

    close = () => {
        this.isActive = false;
        this.element.classList.remove(CLASS_ACTIVE);
    };
}
