function getX(e) {
    if (e.changedTouches) return e.changedTouches[0].clientX;
    else return e.clientX;
}

function getY(e) {
    if (e.changedTouches) return e.changedTouches[0].clientY;
    else return e.clientY;
}

function getType(e){
    let type;
    switch(e.type){
        case 'mousedown':
        case 'mouseup':
        case 'mousemove':
            type = 'mouse';
            break
        case 'touchstart':
        case 'touchend':
        case 'touchmove':
            type = 'touch';
            break
        case 'pointerdown':
        case 'pointerup':
        case 'pointermove':
            type = 'pointer';
            break
    }

    return type;
}

export class Events {
    _onDown = () => {};
    _onMove = () => {};
    _onUp = () => {};

    down = false;
    moved = false;
    type = '';
    xStart = 0;
    yStart = 0;
    xDist = 0;
    yDist = 0;
    tap = false;

    constructor(config) {
        this.config = config;
        this.create();
    }

    create = () => {
        const { el } = this.config;

        el.addEventListener('touchstart', this._handleDown, {passive: true});
        window.addEventListener('touchmove', this._handleMove, {passive: false});
        window.addEventListener('touchend', this._handleUp, {passive: true});

        el.addEventListener('mousedown', this._handleDown, {passive: true});
        window.addEventListener('mousemove', this._handleMove, {passive: false});
        window.addEventListener('mouseup', this._handleUp, {passive: true});

        window.addEventListener('resize', this._handleResize, {passive: true});
    };

    destroy = () => {
        const { el } = this.config;

        el.removeEventListener('touchstart', this._handleDown, {passive: true});
        window.removeEventListener('touchmove', this._handleMove, {passive: false});
        window.removeEventListener('touchend', this._handleUp, {passive: true});

        el.removeEventListener('mousedown', this._handleDown, {passive: true});
        window.removeEventListener('mousemove', this._handleMove, {passive: false});
        window.removeEventListener('mouseup', this._handleUp, {passive: true});

        window.removeEventListener('resize', this._handleResize, {passive: true});
    };


    _handleResize = () => {
        const { onResize } = this.config;
        onResize();
    }

    _handleDown = (e) => {
        const { onDown } = this.config;
        if (this.down) return;
        this.type = getType(e);
        this.down = true;
        this.moved = false;
        this.tap = false;
        this.xStart = getX(e);
        this.yStart = getY(e);
        onDown();
    }

    _handleMove = (e) => {
        if (!this.down) return;
        const { onMove } = this.config;
        if (getType(e) !== this.type) return;

        this.xDist = getX(e) - this.xStart;
        this.yDist = getY(e) - this.yStart;

        if (!this.moved && Math.abs(this.yDist) > Math.abs(this.xDist)) {
            // If moved Y more than X (i.e scrolling)
            // this.down = false;

            return;
        } else {
            // Otherwise stop mobile scroll
            console.log('stop swipe')
            e.preventDefault();
        }

        // if (this.down) e.preventDefault()

        this.moved = true;
        onMove();
    }

    _handleUp = (e) => {
        const { onUp } = this.config;
        if (!this.down) return;
        if (getType(e) !== this.type) return;
        this.down = false;
        this.xDist = getX(e) - this.xStart;

        if (Math.abs(this.xDist) < 5) {
            // Tap
            this.tap = true;
            return;
        } //else e.preventDefault();

        onUp(this.xDist);
    }
}
