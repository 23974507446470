import { Controller } from "stimulus";

export default class extends Controller {
    initialize() {
        fetch('/content/json/regions.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                let regions = (data);
                import(/* webpackChunkName: "offers-tiles" */ '../components/OffersTiles').then(module => {
                    // console.log(this.element, module);
                    module.default(this.element, {regions: regions });
                })

                // Process the JSON data here
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }
}