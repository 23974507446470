import { af as AF } from '@gladeye/af';
import { Events } from './Events';
import { Slide } from './Slide';

export default class OpSlider {

    length;

    x = 0;
    xDest = 0;
    xStart = 0;
    direction = null;
    selected = 0;
    stopped = true;
    isDisabled = false;
    isDraggable = true;

    titleCheckpoints = [];

    titleIds = [];

    slides = [];
    frameRect = null;

    autoplayTimeout;

    constructor(config) {
        this.config = config;
        const { slider, slides, slideAnimation, preSelect, titles } = config;
        this.length = slides.length;

        if (this.length < 2) {
            this.disable();
            return;
        }

        if(titles){
            this.setupTitles();
        }
        if (preSelect) {
            this.selected = preSelect;
        }


        if(window.location.hash) {
           this.titleIds.forEach((element, index) => {
               if(element == window.location.hash.toLowerCase().substr(1, window.location.hash.length)){
                   this.selected = this.titleCheckpoints[index - 1];
               }
           })
        }

        this.af = AF();
        this.create();
    }

    setupTitles = () => {
        const { titles } = this.config;
        const titleTilesCounts = titles.map((titleEl, index) => { return titleEl.getAttribute('data-tiles-count')});

        const titleIds = titles.map((titleEl, index) => { return titleEl.getAttribute('idgrab')});

        let titleCheckpoints = [];
        let checkpointCounter = 0;
        titleTilesCounts.forEach((num, i) => {
            num = parseInt(num);
            checkpointCounter += num;
            titleCheckpoints.push(checkpointCounter);
        });
        let titleIDSSearch = [];
        titleIds.forEach((element, i) => {
            titleIDSSearch.push(element);
        });

        this.titleIds = titleIDSSearch;
        this.titleCheckpoints = titleCheckpoints;
    }

    disable = () => {
        const { slider, slides, slideAnimation } = this.config;
        if (this.isDisabled) return;
        this.isDisabled = true;
        slider.classList.add('is-slider-inactive');
        this.xDest = 0;
        if (slideAnimation) slides.forEach((s, i) => slideAnimation({ index: i, sideProgress: 0 }));
    };

    enable = () => {
        const { slider } = this.config;
        if (!this.isDisabled) return;
        this.isDisabled = false;
        slider.classList.remove('is-slider-inactive');
    };

    disableDrag = () => {
        if (!this.isDraggable) return;
        const { slider } = this.config;
        this.isDraggable = false;
        this.selectClosest();
        slider.classList.add('is-drag-disabled');
    };

    enableDrag = () => {
        if (this.isDraggable) return;
        const { slider } = this.config;
        this.isDraggable = true;
        slider.classList.remove('is-drag-disabled');
    };

    create = () => {
        const { slider, slides, next, prev, autoplay, titles } = this.config;
        const { onDown, onMove, onUp, onResize, tick } = this;

        tick();
        this.slides = slides.map((s, index) => { return new Slide(s, index)});
        this.events = new Events({ el: slider, onDown, onMove, onUp, onResize });

        if (next) next.targets.forEach(t => t.addEventListener('click', this.selectNext));
        if (prev) prev.targets.forEach(t => t.addEventListener('click', this.selectPrev));

        if (titles) {
            titles.forEach((t, i) => t.addEventListener('click', function(){
                if(i == 0){
                    this.select(0);
                }else{
                    this.select(this.titleCheckpoints[i-1]);
                }
            }.bind(this)));
        }


        if (autoplay) {
            const self = this;

            this.autoplay = {
                timeout: null,
                start: function() {
                    clearTimeout(this.timeout);

                    this.timeout = setTimeout(() => {
                        self.selectNext();
                        this.start();
                    }, autoplay.delay || 2000);
                },
                stop: function() {
                    clearTimeout(this.timeout)
                },
                restart: function() {
                    this.stop();
                    this.start();
                }
            }

            this.autoplay.start();
        };

        this._update();
        this._render();
        this.af.addRead(this._read);
        this.af.addWrite(this._write);

        this.select(this.selected);
    };

    destroy = () => {
        this.events.destroy();
        this.af.removeRead(this._read);
        this.af.removeWrite(this._write);
    };

    get closestItem() {
        const compare = (a, b) => {
            const { xDest } = this;
            const ax = a.calcX(this.frameRect, this.x);
            const bx = b.calcX(this.frameRect, this.x);

            // console.log(`a. index: ${a.index}, x: ${ax}, xDest: ${xDest}, diff: ${xDest - ax}`);
            // console.log(`b. index: ${b.index}, x: ${bx}, xDest: ${xDest}, diff: ${xDest - bx}`);
            return Math.abs(xDest - ax) < Math.abs(xDest - bx) ? a : b;
        }

        const { length } = this.slides;
        const item = this.slides.reduce(compare);
        let index = item.index;

        if (index === this.selected) {
            if (item.progress < -0.2) index = index + 1 >= length ? index : index + 1;
            if (item.progress > 0.2) index = index - 1 < 0 ? index : index - 1;
        }

        return index;
    };

    get next() {
        const { selectCount } = this.config;
        const { selected: s, length: l } = this;

        let index = s + 1;
        index = index >= l ? 0 : index;

        if (selectCount) {
            const diff = l - index;
            index = diff < selectCount ? 0 : index
        }

        return index;
    };

    get prev() {
        const { selected: s, length: l } = this;
        return s - 1 < 0 ? l - 1 : s - 1;
    };

    select = (index) => {
        if (this.isDisabled) return;
        const { onSelect, selectCount, titles } = this.config;
        const { length: l } = this;

        // if selectCount specified
        // Meaning it needs to have at least selectCount away from th end
        // Example: 7 items array. selectCount = 3.
        // We are trying to select item index = 5.
        // Not allowed as needs to be at least 3 items on the screen.
        // So force to select index = 4; So 3 items stay visible in the frame from the left of selected
        if (selectCount) {
            const diff = l - index;
            index = diff < selectCount ? l - selectCount : index;
        }

        if(this.slides[index]) {
            this.xDest = this.slides[index].calcX(this.frameRect, this.x);
        }
        this.xStart = this.xDest;

        if (onSelect) onSelect({ newIndex: index, prevIndex: this.selected });

        this.selected = index;

        // move line to current title
        if(titles){
            this.titleCheckpoints.forEach((cp, i) => {
                if(i == 0){ // First tab / on load case really
                    if(index >= 0 && index < cp){
                        titles[i].classList.add('is-selected');
                    }else{
                        titles[i].classList.remove('is-selected');
                    }
                }else{
                    if(index >= this.titleCheckpoints[i-1] && index < cp){
                        titles[i].classList.add('is-selected');
                    }else{
                        titles[i].classList.remove('is-selected');
                    }
                }
            })
        }


        if (this.autoplay) this.autoplay.restart();
    };

    selectClosest = () => {
        this.select(this.closestItem);
    };

    selectNext = () => {
        this.select(this.next);
    };

    selectPrev = () => {
        this.select(this.prev);
    };

    tick = () => {
        const { frame } = this.config;
        this.frameRect = frame.getBoundingClientRect();
    };

    onResize = () => {
        this.tick();
        this.select(this.selected);
    };

    onDown = () => {
        if (!this.isDraggable) return;
        if (this.isDisabled) return;
        // this.xStart = this.xAnim;
    }

    onMove = () => {
        if (!this.isDraggable) return;
        if (this.isDisabled) return;
        const { xDist } = this.events;

        this.xDest = this.xStart + xDist;

        if (this.autoplay) this.autoplay.restart();
    }

    onUp = (dist) => {
        if (!this.isDraggable) return;
        if (this.isDisabled) return;
        const { stopMove } = this.config;

        // Just to make swiping esier
        // On release add distance of mouse traveled on top of where the slides should be
        // So it travels further as released
        this.xDest = this.xDest + dist;
        this.xStart = this.xDest;

        if (stopMove) {
            if (dist < 0) this.selectNext();
            else this.selectPrev();
        } else {
            this.af.onNextRead(this.selectClosest);
        }
    }

    get isAnimating() {
        return Math.abs(this.xDest - this.x) >= 1;
    }

    _read = () => {
        if (this.isAnimating) {
            this._update();
            // console.log(this.slides[0].progress, this.slides[1].progress);
        }
    };

    _write = () => {
        if (this.isAnimating) {
            this.stopped = false;
            this._render();
        } else {
            // If first time here
            if (!this.stopped) {
                this.x = Math.round(this.xDest); // To make precise in the end
                // this.slides[this.selected].progress = 0;
                // this.slides.forEach(s => s.stop());
                this._render();
            }
            this.stopped = true;
            return;
        }
    };

    _update = () => {
        this.slides.forEach(s => s.tick(this.frameRect));
    };

    _render = () => {
        const { list, speed, slideAnimation, stopMove } = this.config;

        this.x += (this.xDest - this.x) * (speed || 0.07);
        this.direction = this.xDest > this.x ? -1 : 1;

        if (!stopMove) {
            list.style.transform = `translateX(${this.x}px) translateZ(0)`;
        }

        if (slideAnimation) this.slides.forEach(slideAnimation);
    };
}

