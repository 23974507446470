import { Controller } from "stimulus";
import wrap from 'word-wrap';

export default class extends Controller {
    initialize() {
        let length = 1;
        let indent = '<span>';
        let newline = '</span>';

        this.element.innerHTML = wrap(this.element.innerHTML.trim(), {
            width: length,
            trim: true,
            escape: function(string){
                return String(indent) + string.trim() + String(newline);
            }
        });

        this.element.classList.add('is-words-ready')
    }
}