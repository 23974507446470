import { Controller } from "stimulus";
import { w, windowLoad } from '../utils';

export default class extends Controller {

    connect() {
        const handleResize = e => {
            this.element.style.height = `${w.h}px`;
        };

        window.addEventListener('resize', handleResize);
        windowLoad(handleResize);
    }
}
