import { Controller } from "stimulus";
import OpSlider from "../components/op-slider";
import { windowLoad, w, br_table } from '../utils';

import anime from "animejs";
import roundTo from 'round-to';

export default class extends Controller {

    static targets = [
        "frame",
        "list",
        "item",
        "image",
        "left",
        "right",
        "progress",
        "title"
    ];

    connect() {
        if(!this.hasItemTarget) return;
        windowLoad(this.initSlider);
        window.addEventListener('resize', this.handleResize);
    }

    initSlider = () => {
        this.length = this.itemTargets.length;

        if (this.length < 2) {
            this.progressTarget.style.display = 'none';
            return;
        }

        // const timelines = this.imageTargets.map(item => {
        //     return anime.timeline({
        //         autoplay: false,
        //         duration: 1000,
        //         targets: item,
        //     }).add({
        //         translateX: [-30, 0],
        //         translateZ: 0,
        //         easing: 'linear',
        //     })
        //     .add({
        //         translateX: [0, 30],
        //         translateZ: 0,
        //         easing: 'linear',
        //     })
        // });

        // const slideAnimation = ({ index, windowProgress }) => {
        //     const pr = roundTo((windowProgress + 1) / 2, 6);
        //     timelines[index].seek(timelines[index].duration * pr);
        // };


        const p = this.progressTarget.querySelector('span');

        const percent = 100/this.length;
        p.style.width = `${percent}%`;

        const onSelect = ({newIndex, prevIndex}) => {
            p.style.transform = `translate3d(${100 * newIndex}%, 0, 0)`;
        };

        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            speed: 0.15,
            prev: { targets: [this.leftTarget] },
            next: { targets: [this.rightTarget] },
            onSelect,
            titles: this.titleTargets,
            // slideAnimation
        };

        this.slider = new OpSlider(config);
        this.handleResize();
    }

    handleResize = () => {
        // if (this.slider) {
        //     if (w.w > br_table) {
        //         console.log('disable')
        //         this.slider.disableDrag();
        //     } else {
        //         console.log('enable')
        //         this.slider.enableDrag();
        //     }
        // }
    }
}
