import { Controller } from "stimulus";
import "url-search-params-polyfill";

export default class extends Controller {
    initialize() {}

    connect() {
        this.element.addEventListener("click", () => {
            let dealer = window.heartland_id;
            if(!dealer){
                dealer = 1;
            }
            window.open(
                "https://digital.heartland.co.nz/#/apply-for-armstrongs-finance/armstrongs-car-loan-calculator?pc=" +
                    dealer,
                "_blank"
            );
        });
    }
}
