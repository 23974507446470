import { Controller } from "stimulus";
import { groupDealerships, formatGroupLabel, excludeDealerships } from "~/js/utils";


const excludedDealers = window.excluded_dealers;
export default class extends Controller {
    initialize() {
        fetch('/content/json/regions.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                let regions = (data);
                let dealershipsGrouped = groupDealerships(regions);
                let finalDealerships = excludeDealerships(excludedDealers, dealershipsGrouped);
                import(/* webpackChunkName: "offer-card-all" */ '../components/OfferCardAll').then(module => {
                    // console.log(this.element, module);
                    module.default(this.element, { finalDealerships: finalDealerships});
                })

                // Process the JSON data here
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }
}