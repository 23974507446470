import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["heading", "content", "text"];

  visible = false;
  calcRefHeight = 0;

  connect() {
    this.init();
    this.headingTarget.addEventListener("click", this.handleClick);
    window.addEventListener("resize", this.handleResize);
  }

  handleClick = () => {
    if (this.visible) {
      // hide it aka set height to ZERO
      this.contentTarget.style.height = 0;
    } else {
      // it's currently invisible, so we need to show it! Get the height ref we calculated
      this.contentTarget.style.height = `${this.calcRefHeight}px`;
    }

    this.visible = !this.visible;
  };

  init = () => {
    this.calcRefHeight = this.textTarget.offsetHeight;

    if (!this.visible)
      // hidden by default
      this.contentTarget.style.height = 0;
  };

  handleResize = () => {
    this.calcRefHeight = this.textTarget.offsetHeight;

    // Check if we're visible or not and adjust height if we are
    if (this.visible) {
      this.contentTarget.style.height = `${this.calcRefHeight}px`;
    }
  };
}
