import { Controller } from "stimulus";
import OpSlider from "./../components/op-slider";
import anime from "animejs";
import roundTo from 'round-to';
import { w, windowLoad } from './../utils';

export default class extends Controller {

    static targets = [
        "frame",
        "list",
        "item",
        "left",
        "right",
        "car",
        "carImg",
        "progress"
    ];

    connect() {
        if(!this.hasItemTarget) return;
        windowLoad(this.init);

        window.addEventListener('resize', this.handleResize);
    }

    init = () => {
        this.length = this.itemTargets.length;

        const timelines = this.itemTargets.map(item => {
            return anime.timeline({
                autoplay: false,
                easing: 'linear',
            }).add({
                targets: item,
                opacity: [0, 1],
                // scaleX: [.9, 1],
                // scaleY: [.9, 1],
                translateZ: 0,
                duration: 500,
            }, 0)
            .add({
                targets: item,
                opacity: [1, 0],
                // scaleX: [1, .9],
                // scaleY: [1, .9],
                translateZ: 0,
                duration: 500,
            }, 500)
        });

        const carTimelines = this.carTargets.map(car => {
            return anime.timeline({
                autoplay: false,
            })
            .add({
                targets: car,
                // translateX: [30, 0],
                translateY: [20, 0],
                translateZ: 0,
                duration: 500,
                easing: 'linear',
            }, 0)
            .add({
                targets: car,
                // translateX: [0, 30],
                translateY: [0, 20],
                translateZ: 0,
                duration: 500,
                easing: 'linear',
            }, 500)
        });

        const slideAnimation = ({ index, sideProgress }) => {
            const pr = roundTo((sideProgress + 1) / 2, 6);
            // console.log(pr, sideProgress)
            if (w.w > 820) {
                timelines[index].seek(timelines[index].duration * pr);
            } else {
                timelines[index].seek(timelines[index].duration * 0.5)
            }
            
            carTimelines[index].seek(carTimelines[index].duration * pr);
        };

        // const p = this.progressTarget;
        
        // const percent = 100/length;
        // p.style.width = `${percent}%`;

        // const onSelect = ({newIndex, prevIndex}) => {
        //     p.style.transform = `translate3d(${100 * newIndex}%, 0, 0)`;
        // };
        
        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            prev: { targets: [this.leftTarget] },
            next: { targets: [this.rightTarget] },
            get selectCount () {
                let count = 3;

                if (this.length < 3) count = this.length;

                if (w.w <= 820) {
                    count = this.length < 2 ? 1 : 2;
                }

                if (w.w <= 600) count = 1;

                return count;
            },
            // onSelect,
            slideAnimation,
        };

        this.slider = new OpSlider(config);
        this.handleResize();
    }

    handleResize = () => {
        if (this.slider) {
            if (
                (w.w > 820 && this.length < 4) ||
                (w.w > 600 && this.length < 3) ||
                (this.length < 2)
            ) {
                // console.log('disable')
                this.slider.disable();
            } else {
                // console.log('enable')
                this.slider.enable();
            }
        }
    }
}
