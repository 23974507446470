import { Controller } from "stimulus";
import { favouritesService } from '~/js/_services';

export default class extends Controller {
    isFavourite = false;

    connect() {
        this.id = this.element.getAttribute('data-id');
        this.element.addEventListener('click', this.toggle);
        this.subscription = favouritesService.current.subscribe(this.check);
    };
    
    check = () => {
        this.isFavourite = favouritesService.isFavourite(this.id);

        if (this.isFavourite) this.add();
        else this.remove();
    };

    toggle = (e) => {
        e.preventDefault();
        const { isFavourite } = this;
        const { addFavourite, removeFavourite} = favouritesService;

        if (isFavourite) {
            removeFavourite(this.id);
        } else {
            addFavourite(this.id);
        }
    };

    add = () => {
        this.element.classList.add('is-favourite');
    }

    remove = () => {
        this.element.classList.remove('is-favourite');
    }
}