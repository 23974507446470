import roundTo from 'round-to';
import { w } from '~/js/utils';

export class Slide {

    progress = null;
    sideProgress = null;
    windowProgress = null;

    constructor(slide, index) {
        this.slide = slide;
        this.index = index;
    }

    tick = (frameRect) => {
        this.rect = this.slide.getBoundingClientRect();
        this.progress = this.calcProgress(frameRect);
        this.calcSideProgress(frameRect);
        this.calcWindowProgress();
    };

    calcX = (frameRect, x) => {
        this.tick(frameRect);
        const { left: l } = this.rect;
        const { left: fl } = frameRect;

        return roundTo(x + fl - l, 4);
    };

    calcProgress = (frameRect) => {
        const { left: l, width: w } = this.rect;
        const { left: fl, width: fw  } = frameRect;
        const left = fl - l;
        return roundTo((1 - (left + w) / (fw + w)) * 2 - 1, 4);
    };

    calcWindowProgress = () => {
        const { left, width } = this.rect;
        this.windowProgress = roundTo((1 - (left + width) / (w.w + width)) * 2 - 1, 4);
    };

    stop = () => {
        this.progress = roundTo(this.progress, 1);
        this.sideProgress = roundTo(this.sideProgress, 1);
    }

    calcSideProgress = (frameRect) => {
        const { left: l, width: w } = this.rect;
        const { left: fl, width: fw  } = frameRect;

        if (l + w > fl + fw) {
            // to the right of frame
            // Includes item width
            const rect = { left: fl + fw - w, width: w };
            this.sideProgress = this.calcProgress(rect);
        } else if (l < fl) {
            // to the left of frame
            // Includes item width
            const rect = { left: fl, width: w };
            this.sideProgress = this.calcProgress(rect);
        } else {
            // Inside frame
            this.sideProgress = 0;
        }
    };
}
