import { Controller } from "stimulus";


export default class extends Controller {
    initialize() {
        import(/* webpackChunkName: "news-filter" */ '../components/NewsList').then(module => {
            module.default(this.element, { });
        })
    }

}
