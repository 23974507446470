import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [

    ];

    initialize() {

    }
    connect() {
        this.email = "";
        this.phone =  "";
        this.priceNote = this.element.querySelector('[data-price-note]');
        this.priceCalculator = this.element.querySelector('[data-price-calculator]')
        this.ctaTarget = this.element.querySelector('[data-cta]')
        this.reserved = this.element.querySelector('[data-reserved]');
        console.log(this.data.get('depositId'));
        this.submitCallback = (e, formId, ...args) => {
            let depositId = parseInt(this.data.get('depositId'), 10);
            if(formId === depositId) {
                if (this.priceNote) {
                    this.priceNote.style.display = "none";
                }
                if (this.priceCalculator) {
                    this.priceCalculator.style.display = "none";
                }
                if (this.ctaTarget) {
                    this.ctaTarget.style.display = "none";
                }
                if (this.reserved) {
                    this.reserved.style.display = "block";
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'FormSubmit',
                    'phone': window.phone,
                    'email': window.email
                });

            }

        }
        gform.addAction( 'gform_input_change', function( elem, formId, fieldId ) {
            const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if(regexEmail.test(elem.value)){
                window.email = elem.value;
            }
            const regexPhone = /^(?:\+?\d{1,3})?[-.\s]?(\(?\d{2,4}?\)?)[-.\s]?\d{3,4}[-.\s]?\d{4}$/;
            if(regexPhone.test(elem.value)){
                window.phone = elem.value;
            }
            const fieldSpans = document.querySelectorAll(`span[data-gform-field-id="${fieldId}"]`);

            fieldSpans.forEach(span => {
                span.innerHTML = elem.value;
            });
        }, 10);

        jQuery(window.document).on("gform_confirmation_loaded", this.submitCallback);

        // console.log(this.priceCalculatorTarget);
        // console.log(this.ctaTarget);

    }
    disconnect() {
        jQuery(window.document).off("gform_confirmation_loaded", this.submitCallback);
    }
}