import { Controller } from "stimulus";
import { processMakes } from "~/js/utils";

export default class extends Controller {

    static targets = [
        "sort",
        "tile",
    ];

    makes = [{ value: '', label: 'Select brand' }];
    tiles = [];

    initialize() {
        let data = document.querySelector('#news_data');
        if (data) {
            data = JSON.parse(data.innerText)
        }
        
        if (data) {
            data.forEach(d => {
                const { brands } = d;

                if (brands) {
                    brands.forEach(brand => {
                        const exists = this.makes.findIndex(m => m['ID'] === brand['ID']);

                        if (exists === -1) {

                            this.makes.push({
                                value: brand['post_title'], 
                                label: brand['post_title'], 
                                id: brand['ID'],
                                brand
                            });
                        }
                    });
                }
            })
        };

        if (this.makes.length > 1) {
            console.log(this.makes);
            import(/* webpackChunkName: "select" */ '../partials/Select').then(module => {
                // console.log(this.element, module);
                module.SelectDOM(this.sortTarget, {
                    pre: 'Sort make:',
                    name: `sort`,
                    options: this.makes,
                    onChange: this.handleChange,
                    modifiers: ['form'],
                });
            })
        }
    }

    connect() {
        this.tiles = this.tileTargets.map(tile => {
            let isRemoved = false;

            const attr = tile.getAttribute('data-brands');
            const parent = tile.parentNode;

            let brands = [];
            if (attr) {
                brands = attr.split(',');
            }

            const append = () => {
                console.log('append isRemoved', isRemoved, tile)
                if (!isRemoved) return;
                isRemoved = false;
                parent.appendChild(tile);
            };

            const remove = () => {
                if (isRemoved) return;
                isRemoved = true;
                tile = parent.removeChild(tile);
            };

            const check = (id) => {
                // Remove all from dom, so the order of appending is correct
                remove();

                // If no id, render all
                if (!id) {
                    append();
                    return;
                }

                // Otherwise check if has this id
                if (brands.some(brandID => parseInt(brandID) === parseInt(id))) {
                    append();
                } else {
                    remove();
                }
            };

            return {
                tile,
                parent,
                brands,
                check
            }
        });
    }

    handleChange = ({ id }) => {
        this.tiles.reverse().forEach(tile => tile.check(id));
    }
}