import axios from 'axios';

let csrfMeta = document.querySelector('meta[name="csrf-token"]')

let csrfToken = csrfMeta && csrfMeta.getAttribute('content');

export const axiosInstance = axios.create({
    baseURL: '',
    headers: {'X-CSRF-TOKEN': csrfToken}
})

// X-CSRF-TOKEN
// meta name csrf-token