import { Controller } from "stimulus";
import OpSlider from "../components/op-slider";
import { windowLoad, w } from '../utils';

export default class extends Controller {

    static targets = [
        "frame",
        "list",
        "item",
        "progress"
    ];

    connect() {
        if(!this.hasItemTarget) return;
        windowLoad(this.init);
    }

    init = () => {
        this.length = this.itemTargets.length;
        const p = this.progressTarget;
        
        const percent = 100/this.length;
        p.style.width = `${percent}%`;

        const onSelect = ({newIndex}) => {
            p.style.transform = `translate3d(${100 * newIndex}%, 0, 0)`;
        };

        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.itemTargets,
            get stopMove () {
                return w.w > 768;
            },
            onSelect
        };

        this.slider = new OpSlider(config);
    }

}
