/*  
    Ken's function
    Goes up the DOM and finds specific parent

    let target = up(e.target, function(parent) {
        return parent.classList.contains('js_slide');
    });
*/
export function up(el, check) {
    let node = el, found = check(node);

    while(!!node && !found) {
        node = node.parentElement;
        found = check(node);
    }

    return node;
}