const CLASS_OPEN = 'is-popup-open';

import { up } from '~/js/utils';

export default class PopupForm {
    _instance;
    form;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor() {
        this.element = document.getElementById('popup-form');

        import(/* webpackChunkName: "popup-form" */ './PopupForm').then(module => {
            this.form = module.default(this.element, { });
            this.open = this.form.open;
            
        })
    }
}
