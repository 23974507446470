import React from "react";

export function processFilterOn(filter, state, makes, models, regions, dealerships, years) {
    state = addSelfToState(filter, state);
    if(filter['make']){
        state = addMakeToState(filter, state, makes);
    }
    if(filter['model']){
        state = addModelToState(filter, state, models)
    }
    if(filter['car-history'].length > 0){
        state = addCarHistoryToState(filter, state)

    }
    if(filter['color']){
        state = addColorToState(filter, state);
    }
    if(filter['seats'].length > 0) {
        state = addSeatsToState(filter, state);
    }
    if(filter['fuelType'].length > 0) {
        state = addFuelTypeToState(filter, state);
    }
    if(filter['eType'].length > 0) {
        state = addETypeToState(filter, state);
    }
    if(filter['bodyType'].length > 0){
        state = addBodyTypeToState(filter, state);

    }
    state = addExpressDeliveryToState(filter, state);
    if(filter['yearFrom'] || filter['yearTo']){
        state = addYearToState(filter, state, years);
    }
    if(filter['priceFrom'] || filter['priceTo']){
        state = addPriceToState(filter,state);
    }
    if(filter['odometerFrom'] || filter['odometerTo']){
        state = addOdometerToState(filter,state);
    }
    state = addOnSaleToState(filter,state);
    state = addDiscountedToState(filter,state);
    if(filter['region']) {
        state = addRegionToState(filter, state, regions)
    }
    if(filter['dealership']){
        state = addDealershipToState(filter, state, dealerships)
    }
    state = addUrlArrayToState(state);
    return state;
}

export function processFilterOff(filter, state, makes, models, regions, dealerships, years) {
    state = removeSelfFromState(filter, state);
    if(filter['make']){
        state = removeMakeFromState(filter, state);
    }
    if(filter['model']){
        state = removeModelFromState(filter, state, models)
    }
    if(filter['car-history'].length > 0){
        state = removeCarHistoryFromState(filter, state)
    }
    if(filter['color']){
        state = removeColorFromState(filter, state);
    }
    if(filter['seats'].length > 0) {
        state = removeSeatsFromState(filter, state);
    }
    if(filter['fuelType'].length > 0) {
        state = removeFuelTypeFromState(filter, state);
    }
    if(filter['eType'].length > 0) {
        state = removeEtypeFromState(filter, state);
    }
    if(filter['bodyType'].length > 0){
        state = removeBodyTypeFromState(filter, state);

    }
    state = removeExpressDeliveryFromState(filter, state);
    if(filter['yearFrom'] || filter['yearTo']){
        state = removeYearFromState(filter, state, years);
    }
    if(filter['priceFrom'] || filter['priceTo']){
        state = removePriceFromState(filter,state);
    }
    if(filter['odometerFrom'] || filter['odometerTo']){
        state = removeOdometerFromState(filter,state);
    }
    state = removeOnSaleFromState(filter,state);
    state = removeDiscountFromState(filter,state);
    if(filter['region']){
        state = removeRegionFromState(filter, state, regions)
    }
    if(filter['dealership']){
        state = removeDealershipFromState(filter, state, dealerships)
    }

    state = addUrlArrayToState(state);
    return state;
}
function addSelfToState(filter, state) {
    state.filters[filter['name']] = true;
    return state;
}
function removeSelfFromState(filter, state) {
    state.filters[filter['name']] = false;
    return state;
}
function addUrlArrayToState(state){
    let filter = state.filters;
    const types = [];
    for (const key in filter) {
        const type = filter[key];
        if (type) types.push(key);
    }
    state.urlArray[17] = types.join(",");
    return state;
}

function addMakeToState(filter, state, makes){
    const make = filter['make'];
    if(make){
        const makeFound = findMakeLabelWithId(make, makes);
        if (typeof makeFound !== "undefined") {
            state.make = makeFound.value;
            state.makeLabel = makeFound.label;
        }
    }
    return state;
}

function findMakeLabelWithId (make, makes){
    const found = makes.find(item =>  {
        return item['MAKE_SEQ'] == make;
    })
    return found;
}
function removeMakeFromState(filter, state){
    if(filter['make'] == state.make){
        state.make = null;
        state.makeLabel = null;
        state.model = null;
        state.modelLabel = null;

        state.urlArray[1] = null;

    }
    return state;
}

function addModelToState(filter, state, models){
    const model = filter['model'];
    if(model){
        const modelFound = findModelLabelWithId(model, models);
       {
            state.model = modelFound.value;
            state.modelLabel = modelFound.label;
        }
    }
    return state;
}

function removeModelFromState(filter, state){
    if(filter['model'] == state.model){
        state.model = null;
        state.modelLabel = null;
    }
    return state;
}
function findModelLabelWithId (model, models){
    const found = models.find(item =>  {
        return item['MODEL_SEQ'] == model;
    })
    return found;
}
function addCarHistoryToState(filter, state){
    const history = filter['car-history'];
    if(history) {
        history.forEach((item, index) => {
            state.carHistory[item] = true;
        })
        let string = history.join(',');
    }
    return state;
}

function removeCarHistoryFromState(filter, state){
    filter['car-history'].forEach((item, index) => {
        state.carHistory[item] = false;
    })
    return state;
}
function addColorToState(filter, state){
    const color = filter['color'];
    const invert = filter['color-invert'];


    if(invert && color){
        for (const key in  state.colours) {
            state.colours[key]['active'] = true;
        }
    }

    if(color) {
        let assign = !invert;
        color.forEach((item, index) => {
            console.log(item);
            if(  state.colours[item['color']]){
                state.colours[item['color']]['active'] = assign;
            }
        })
        // let string = color.join(',');
    }
    return state;
}
function removeColorFromState(filter, state){
    const color = filter['color'];

    const invert = filter['color-invert'];
    if(invert && color){
        for (const key in  state.colours) {
            state.colours[key]['active'] = false;
        }
    }



    if(color) {
        let string = "";
        color.forEach((item, index) => {
            if(  state.colours[item['color']]){
                state.colours[item['color']]['active'] = false;
            }
        })
    }
    return state;
}
function addBodyTypeToState(filter, state){
    const bodyType = filter['bodyType'];
    if(bodyType) {
        bodyType.forEach((item, index) => {
            state.bTypes[item] = true;
        })

    }
    return state;
}
function removeBodyTypeFromState(filter, state){
    const bodyType = filter['bodyType'];
    if(bodyType) {
        bodyType.forEach((item, index) => {
            state.bTypes[item] = false;
        })

    }
    return state;
}

function addSeatsToState(filter, state){
    const seats = filter['seats'];
    if(seats) {
        seats.forEach((item, index) => {
            state.seatNumbers[item] = true;
        })

    }
    return state;
}
function removeSeatsFromState(filter, state){
    const seats = filter['seats'];
    if(seats) {
        seats.forEach((item, index) => {
            state.seatNumbers[item] = false;
        })

    }
    return state;
}
function addFuelTypeToState(filter, state){
    const fuelType = filter['fuelType'];
    if(fuelType) {
        fuelType.forEach((item, index) => {
            state.fuelTypes[item] = true;
        })

    }
    return state;
}
function removeFuelTypeFromState(filter, state){
    const fuelType = filter['fuelType'];
    if(fuelType) {
        fuelType.forEach((item, index) => {
            state.fuelTypes[item] = false;
        })

    }
    return state;
}
function addETypeToState(filter, state){
    const eType = filter['eType'];
    if(eType) {
        eType.forEach((item, index) => {
            state.eType[item] = true;
        })

    }
    return state;
}
function removeEtypeFromState(filter, state){
    const eType = filter['eType'];
    if(eType) {
        eType.forEach((item, index) => {
            state.eType[item] = false;
        })

    }
    return state;
}
function addRegionToState(filter, state, regions){
    const region = filter['region'];
    if(region){
        const regionFound = findRegionLabelWithId(region, regions);
        {
            state.region = regionFound.value;
            state.regionLabel = regionFound.label;
        }
    }
    return state;
}
function removeRegionFromState(filter, state, regions){
    const region = filter['region'];
    if(region ){
       if(state.region == region){
           state.region = null;
           state.regionLabel = null;
           state.dealership = null;
           state.dealershipLabel = null;
           state.urlArray[9] = null;
       }
    }
    return state;
}

function findRegionLabelWithId (region, regions){
    const found = regions.find(item =>  {
        return item['term_id'] == region;
    })
    return found;
}

function addDealershipToState(filter, state, dealerships){
    const dealership = filter['dealership'];
    if(dealership){
        const dealershipFound = findDealershipLabelWithValue(dealership, dealerships);
        {
            state.dealership = dealershipFound.value;
            state.dealershipLabel = dealershipFound.label;
        }
    }
    return state;
}
function removeDealershipFromState(filter, state, dealerships){
    const dealership = filter['dealership'];
    if(dealership){
       if(dealership == state.dealership){
           state.dealership = null;
           state.dealershipLabel = null;
       }
    }
    return state;
}
function findDealershipLabelWithValue (dealership, dealerships){
    const found = dealerships.find(item =>  {
        return item['value'] == dealership;
    })
    return found;
}
function addExpressDeliveryToState(filter, state, dealerships){
    const expressDelivery = filter['expressDelivery'];
    if(expressDelivery){
       if(expressDelivery.length > 0){
           state.expressDelivery = true;
       }
    }
    return state;
}

function removeExpressDeliveryFromState(filter, state, dealerships){
    const expressDelivery = filter['expressDelivery'];
    if(expressDelivery){
        if(expressDelivery.length > 0){
            state.expressDelivery = false;
        }
    }
    return state;
}
function addYearToState(filter, state, years){
    const yearFrom = filter['yearFrom'] ?? state.year.from;
    const yearTo = filter['yearTo'] ?? state.year.to;
    let range = {to: null, from: null};

    const itemFrom = years.find(
        (o) => parseInt(o.value) === parseInt(yearFrom)
    );

    if (typeof itemFrom !== "undefined") {
        range.from = itemFrom.value;
    }
    const itemTo = years.find(
        (o) => parseInt(o.value) === parseInt(yearTo)
    );

    if (typeof itemTo !== "undefined") {
        range.to = itemTo.value;
    }

    state.year = range;
    return state;
}
function removeYearFromState(filter, state, years){
    const yearFrom = filter['yearFrom'];
    const yearTo = filter['yearTo'];

    let range = state.year;
    if(yearFrom == state.year.from){
        range.from = null;
    }

    if(yearTo == state.year.to){
        range.to = null;
    }


    state.year = range;
    return state;
}

function addPriceToState(filter, state, dealerships){
    const priceFrom = filter['priceFrom'] ?? state.price.from;
    const priceTo = filter['priceTo'] ?? state.price.to;
    const price = {
        from: priceFrom ? parseInt(priceFrom) : null,
        to : priceTo ? parseInt(priceTo) : null,
    }
    state.price = price;

    return state;
}
function removePriceFromState(filter, state, dealerships){
    const priceFrom = filter['priceFrom'];
    const priceTo = filter['priceTo'];
    const price = state.price;

    if(priceFrom == price.from){
        price.from = null
    }
    if(priceTo == price.tp){
        price.to = null
    }
    state.price = price;

    return state;
}
function addOdometerToState(filter, state){
    const odometerFrom = filter['odometerFrom'] ??  state.odometer.from;
    const odometerTo = filter['odometerTo'] ??  state.odometer.to;

    const odometer = {
        from: (odometerFrom) ? (odometerFrom) : null,
        to: (odometerTo) ? (odometerTo) : null,
    }
    state.odometer = odometer;
    return state;
}

function removeOdometerFromState(filter, state){
    const odometerFrom = filter['odometerFrom'];
    const odometerTo = filter['odometerTo'];

    const odometer = state.odometer;
   if(odometer.to == odometerTo){
       odomoter.to = null;
   }
    if(odometer.from == odometerFrom){
        odomoter.from = null;
    }
    state.odometer = odometer;
    return state;
}
function addOnSaleToState(filter, state){
    const onSale = filter['onSale'];
    if(onSale){
        if(onSale.length > 0){
            state.onSale = true;
        }
    }
    return state;
}

function removeOnSaleFromState(filter, state){
    const onSale = filter['onSale'];
    if(onSale){
        if(onSale.length > 0){
            state.onSale = false;
        }
    }
    return state;
}
function addDiscountedToState(filter, state){

    const discounted = filter['discountedVehicles'];
    if(discounted){
        if(discounted.length > 0){
            state.special = true;
        }
    }
    return state;
}

function removeDiscountFromState(filter, state){

    const discounted = filter['discountedVehicles'];
    if(discounted){
        if(discounted.length > 0){
            state.special = false
        }
    }
    return state;
}