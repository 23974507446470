import { Controller } from "stimulus";

const CLASS_REVEALED = "is-revealed";

export default class extends Controller {

    isRevealed = false;

    static targets = [
        "content",
    ];

    toggle = (e) => {
        if (!this.buttonContent) this.buttonContent = e.target.innerHTML;

        if (!this.isRevealed) {
            this.isRevealed = true;
            this.contentTarget.classList.add(CLASS_REVEALED);
            e.target.innerHTML = 'Less details';
        } else {
            this.isRevealed = false;
            this.contentTarget.classList.remove(CLASS_REVEALED);
            e.target.innerHTML = this.buttonContent;
        }
    };
}
