export function insertParam(key, value) {
    key = encodeURI(key);
    const isEmpty = value === null || value === '';
    const valueEnc = encodeURI(value);

    let kvp = document.location.search.substr(1).split('&');

    let i = kvp.length; 
    let x;

    while (i--) {
        x = kvp[i].split('=');

        if (x[0] === key) {
            if (isEmpty) {
                kvp.splice(i, 1);
            } else {
                x[1] = valueEnc;
                kvp[i] = x.join('=');
            }

            break;
        }
    }

    if (i < 0 && !isEmpty) kvp[kvp.length] = [key,valueEnc].join('=');

    changeQuery(kvp.join('&')); 
}

export function changeQuery(query) {
    if (history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${query}`;
        window.history.pushState({path:newurl},'',newurl);
    }
}