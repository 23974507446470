import { axiosInstance } from './config';
// import { handleSuccess, handleError } from './helpers';

export const formsService = {
    bookTestDrive,
    bookTestDriveSpecific,
    makeAnOffer,
    makeAnEnquiry,
    makeTradeEnquiry,
    bookService,
    partsEnquiry,
    autobodyEnquiry,
    financeEnquiry,
    bookVirtualAppointment,
    bookVirtualAppointmentSpecific,
    heartlandsApiCall,
    turnstileVerification
};

function submitData(data) {
    let soapResponse = false;

    // if(!(data['name'] === 'Parts Enquiry')){
    //     axiosInstance.post('/submitAutoplayLeadGen', data)
    //         .then(function (response) {
    //             console.log("Autoplay Lead Generated");
    //             let str = response['data'];
    //             let xmlDeclaration = 'RecordId:';
    //             let xmlStartIndex = str.indexOf(xmlDeclaration);
    //
    //             if (xmlStartIndex !== -1) {
    //                 let startAfterXml = xmlStartIndex + xmlDeclaration.length;
    //                 soapResponse = str.substring(startAfterXml);
    //             }
    //             data['autoplayLeadId'] = soapResponse;
    //
    //         })
    //         .catch(function (error) {
    //             console.log("Error Generating Autoplay Lead");
    //         })
    //         .finally(function () {
    //             axiosInstance.post('/googleSheetsApi', data)
    //                 .then(function (response) {
    //                     console.log("Autoplay Lead Submitted to GSheets");
    //                 })
    //                 .catch(function (error) {
    //                     console.log("Error Generating GSheets Lead");
    //                 });
    //         })

    if(!(data['name'] === 'Parts Enquiry')){
        axiosInstance.post('/googleSheetsApi', data)
            .then(function (response) {
                console.log("Submitted to GSheets");
                let str = response['data'];
                let insertIdMatch = str.match(/InsertId:(\d+)/);
                let databaseIdMatch = str.match(/DatabaseId:(\d+)/);
                let insertId = insertIdMatch ? insertIdMatch[1] : '';
                let databaseId = databaseIdMatch ? databaseIdMatch[1] : '';
                data['form_database_id'] = parseInt(databaseId);
                data['key_g_sheets'] = parseInt(insertId) + 1;

            })
            .catch(function (error) {
                console.log("Error Generating GSheets Lead");
            })
            .finally(function () {
                axiosInstance.post('/submitAutoplayLeadGen', data)
                    .then(function (response) {
                        console.log("Autoplay Lead Submitted");
                    })
                    .catch(function (error) {
                        console.log("Error Generating GSheets Lead");
                    });
            });
    }


    return axiosInstance.post('/submit', data)

}

function bookTestDrive(data) {
    console.log('bookTestDrive API', data)
    // return axiosInstance.post('/book-test-drive', data);
    return submitData(data);
}

function turnstileVerification(data) {
    console.log('turnstile API verification')
    // return axiosInstance.post('/book-test-drive', data);
    return axiosInstance.post('/submitTurnstileVerification', data);

}

function heartlandsApiCall(data) {
    return axiosInstance.post('/heartlandsFinanceCalculator', data)
}

function bookTestDriveSpecific(data) {
    console.log('bookTestDriveSpecific API', data)
    // return axiosInstance.post('/book-test-drive-specific', data);
    return submitData(data);

}

function makeAnOffer(data) {
    console.log('makeAnOffer API', data)
    // return axiosInstance.post('/make-an-offer', data);
    return submitData(data);

}

function makeAnEnquiry(data) {
    console.log('makeAnEnquiry API', data)
    // return axiosInstance.post('/make-an-enquiry', data);
    if(data.name != 'Careers Page General Enquiry') {
        return submitData(data);
    }else{
        return axiosInstance.post('/submit', data)
    }
}

function makeTradeEnquiry(data) {
    console.log('makeTradeEnquiry API', data)
    // return axiosInstance.post('/make-an-enquiry', data);
    return submitData(data);

}

function bookService(data) {
    console.log('bookService API', data)
    // return axiosInstance.post('/book-service', data);
    return submitData(data);

}

function partsEnquiry(data) {
    console.log('partsEnquiry API', data)
    // return axiosInstance.post('/parts-enquiry', data);
    return submitData(data);

}

function autobodyEnquiry(data) {
    console.log('autobodyEnquiry API', data)
    // return axiosInstance.post('/parts-enquiry', data);
    return submitData(data);

}

function financeEnquiry(data) {
    console.log('financeEnquiry API', data)
    // return axiosInstance.post('/finance-enquiry', data);
    return submitData(data);

}

function bookVirtualAppointment(data) {
    console.log('bookVirtualAppointment API', data)
    // return axiosInstance.post('/book-test-drive', data);
    return submitData(data);

}

function bookVirtualAppointmentSpecific(data) {
    console.log('bookVirtualAppointmentSpecific API', data)
    // return axiosInstance.post('/book-test-drive', data);
    return submitData(data);

}
