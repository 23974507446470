const CLASS_OPEN = 'is-overlay-open';

export default class Overlay {
    _instance;

    onClickFns = [];

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor() {
        this.element = document.getElementById('overlay');
        this.element.addEventListener('click', this.handleClick);
    }

    show = () => {
        if (this.visible) return;
        this.visible = true;
        this.element.classList.add(CLASS_OPEN);
    }

    hide = () => {
        if (!this.visible) return;
        this.visible = false;
        this.element.classList.remove(CLASS_OPEN);
    }

    addOnClick = (fn) => {
        this.onClickFns.push(fn);
    };

    handleClick = () => {
        this.onClickFns.forEach(fn => fn());
    };
}
