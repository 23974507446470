import { Controller } from "stimulus"
import { w } from './../utils';
import { af as AF } from '@gladeye/af';

const CLASS_INVIEW = 'is-inview';
const DEFAULT_THRESHOLD_START = 0.1;
const DEFAULT_THRESHOLD_END = 0.8;

export default class extends Controller {
    progress = 0;

    initialize() {
        this.af = AF();
    }

    connect() {
        this.hasDeactivate = this.element.hasAttribute('op-inview-deactivate');
        this.start = DEFAULT_THRESHOLD_START;
        this.end = DEFAULT_THRESHOLD_END;
        this.class = CLASS_INVIEW;

        const settings = this.element.getAttribute('op-inview');

        if (settings){
            const values = JSON.parse(settings);
            this.start = parseFloat(values.start);
            this.end = parseFloat(values.end);

            if (values[2]) {
                this.class = values[2].trim();
            }
        }
        
        this.af.addRead(this.read);
        this.af.addWrite(this.write);
    }

    read = () => {
        const { top: t, height: h } = this.element.getBoundingClientRect();
        this.progress = 1 - (t + h) / (w.h + h);
    }

    write = () => {
        if (this.progress >= this.start /*&& this.progress <= this.end*/) {
            this.activate();
        } else {
            if (this.hasDeactivate) {
                this.deactivate();
            }
        }
    }

    activate = () => {
        if (!this.hasDeactivate) {
            this.af.removeRead(this.read);
            this.af.removeWrite(this.write);
        }

        this.af.onNextWrite(() => {
            this.element.classList.add(this.class);
        });
    }

    deactivate = () => {
        this.af.onNextWrite(() => {
            this.element.classList.remove(this.class);
        });
    }
}