import { Controller } from "stimulus";

export default class extends Controller {

    connect() {

    };

    initialize() {
        let query = this.element.getAttribute('data-url-value');
        import(/* webpackChunkName: "home-filters" */ '../components/HomeFilters').then(module => {
            module.default(this.element, {query: query });
        })
    }
}
