import { Controller } from "stimulus";
import Tabs from "./../components/tabs";

export default class extends Controller {
    static targets = [
        "tabs",
        "tab",
        "content",
    ];

    connect() {
        const tabsContainer = this.hasTabsTarget ? this.tabsTarget : null;
        if (this.tabTargets.length === 0) return;

        const tabs = new Tabs({
            tabsContainer,
            tabs: this.tabTargets,
            contents: this.contentTargets,
            selected: this.element.getAttribute('data-selected-tab')
        })
    }
}
